// /*
//  * @Author: gaurav
//  * @Date:   2020-07-07 22:27:26
//  * @Last Modified by: gaurav
//  * @Last Modified time: 2020-07-29 03:40:15
//  */
// import React from "react"
// import { GlobalStyles, mediaBreakpoints } from "../styles/styles"
// import Layout from "../components/layout"
// import ProjectsSection from "../components/projectsSection"
// import styled from "@emotion/styled"
// import { css } from "@emotion/react"
// import HeroSection from "../components/heroSection"
// import SEO from "../components/SEO"

// const { small, smallAndMedium, ipadPro } = mediaBreakpoints
// const HomePage = styled("div")`
//   font-size: 1.15vw;
//   @media ${smallAndMedium} {
//     font-size: 100%;
//   }
//   @media ${ipadPro} {
//     font-size: 100%;
//   }
// `

// const ProductPage = () => {
//   const logoStyle = {
//     opacity: 1,
//     transition: "opacity 1s ease-in",
//   }
//   return (
//     <Layout theme="light" config={{ logoStyle }} duration=".1s">
//       <SEO
//         title="Piktorlabs | future-focused product innovation lab"
//         description="We are a multi-disciplinary team of designers, technologists, and researchers who engage in fundamental inquiry to push the boundaries of what’s possible. We adopt a design-centric, experimental, prototype-driven approach to come up with products that solve business problems."
//         pathname="/"
//         article={false}
//       />
//       <HomePage>
//         <GlobalStyles.StyledSection id="landing">
//           <HeroSection />
//           <GlobalStyles.IndexPgSectionWrapper
//             theme="dark"
//             css={css`
//               max-width: 100%;
//               margin: 0;
//               @media ${smallAndMedium} {
//                 max-width: 100%;
//                 margin-bottom: 0;
//               }
//               @media ${ipadPro} {
//                 max-width: 100%;
//               }
//             `}
//           ></GlobalStyles.IndexPgSectionWrapper>
//         </GlobalStyles.StyledSection>

//         <GlobalStyles.StyledSection
//           css={css`
//             @media ${small} {
//               background-color: #f5f5f5;
//             }
//           `}
//           id="projects"
//         >
//           <GlobalStyles.IndexPgSectionWrapper
//             theme="light"
//             css={css`
//               position: relative;
//               margin-top: 0;
//               background: transparent;
//               @media ${small} {
//                 height: auto;
//               }
//             `}
//           >
//             <ProjectsSection />
//           </GlobalStyles.IndexPgSectionWrapper>
//         </GlobalStyles.StyledSection>

//         <GlobalStyles.StyledSection id="partnerWithUs">
//           <GlobalStyles.IndexPgSectionWrapper
//             css={css`
//               @media ${smallAndMedium} {
//                 max-width: 100%;
//               }
//               @media ${small} {
//               }
//             `}
//           ></GlobalStyles.IndexPgSectionWrapper>
//         </GlobalStyles.StyledSection>
//       </HomePage>
//     </Layout>
//   )
// }

// export default ProductPage

import React, { useEffect, useState } from "react"
import "../index.css"
import Ustlogo from "../assets/logos/UST-evolve-logo.svg"
import PiktorLogo from "../assets/logos/piktorlabs-logo-updated.svg"

function ProductPage() {
  const [counter, updateCounter] = useState(4)
  const [coverClass, updateCoverClass] = useState("cover")
  const [middleDivClass, updateMiddleDivClass] = useState("middle-div")
  useEffect(() => {
    if (counter >= 0) {
      setTimeout(() => {
        if (counter > 0) updateCounter(counter - 1)
        if (counter === 4) {
          updateCoverClass("cover fadeOut-four")
        }
        // if (counter === 4) {
        //   updateCoverClass("cover fadeOut-three")
        // } else if (counter === 3) {
        //   // updateCoverClass("cover fadeOut-two")
        // } else if (counter === 2) {
        //   updateCoverClass("cover fadeOut-one")
        // } else
        else if (counter === 1) {
          // updateCoverClass("cover fadeOut-zero")
          updateMiddleDivClass("middle-div middle-div-to-center")
        } else if (counter === 0) {
          window.location.replace("https://www.ust.com/en/evolve")
        }
      }, 2000)
    }
  })

  return (
    <div className="App">
      <div className={coverClass}></div>
      <div className="logo">
        <img src={Ustlogo} />
      </div>

      <div className={middleDivClass}>
        <div className="vertical-line">
          <img src={PiktorLogo} />
        </div>
        <div className="right-div">
          <div className="right-div-header">
            Farewell, Piktorlabs! Now part of UST Evolve.
          </div>
          <div className="right-div-middle">
            <p>{counter}</p>
          </div>
          <div className="right-div-footer">
            click <a href="https://www.ust.com/en/evolve">here</a> to visit the
            UST home page.
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductPage